/**
 * alexdetail theme custom JS main file
 */
jQuery(document).ready(function () {
  $('#fullwidth_play').on('click', function(e) {
    e.preventDefault();

    var videoFile = $('#videoplaceholder').data('file');
    var mimeType = $('#videoplaceholder').data('mime');

    var videoHtml = '<video width="100%" controls autoplay>' +
                    '<source src="' + videoFile + '" type="' + mimeType + '">' +
                    'Your browser does not support the video tag.' +
                    '</video>';

    $('#videoplaceholder').html(videoHtml);
    $('.fullwidth-video').find('.content').slideUp('fast');
});

  /**
   * Mobile Search
   */

  $("#mobile_search_trigger").click(function () { 

    // Toggle slide for the search bar
    $("#mobileSearch").slideToggle("fast");

    // Slide up the notice bar if it exists
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
    }
  });

  /**
   * Mobile Menu Trigger & Function
   */
  // const burgerMenubtn = $('#burger');	
	// const mobileNav = $('.mobile-slide-menu');
	// const closeButton = $('.close'); 

	// burgerMenubtn.click(function(){
	// 	if(mobileNav.hasClass('mobile_nav_active')){
	// 		mobileNav.removeClass('mobile_nav_active');
	// 	} else {
	// 		mobileNav.addClass('mobile_nav_active');
	// 	}
	// });
	// closeButton.click(function(){
	// 	if(mobileNav.hasClass('mobile_nav_active')){
	// 		mobileNav.removeClass('mobile_nav_active');
	// 	} else {
	// 		mobileNav.addClass('mobile_nav_active');
	// 	}
	// });

  //homeheroslider
  if ($(".blog-feed ").length) {
    //Create slider on load
    blogSlider();
  }

  //copy to clip
  $("#copylink").click(function (e) {
    e.preventDefault;
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Post URL copied to clipboard.");
  });


});


$("#more").click(function() {
  $("#morelinksMenu").toggle();
});

// Add class to body when the user has scrolled down
// Debounce function for performance optimization
function debounce(func, delay) {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
}

$(window).scroll(debounce(function () {
  const scrollTop = $(this).scrollTop();
  const body = $('body');
  if (scrollTop > 10 && !body.hasClass('scrolled')) {
    body.addClass('scrolled');
  } else if (scrollTop <= 10 && body.hasClass('scrolled')) {
    body.removeClass('scrolled');
  }
}, 200));


/*header*/
jQuery(document).ready(function ($) {
  let header = $(".scrolled-nav");
  let lastScrolltop = 0;

  hasScrolled();
  setTimeout(hasScrolled, 800);
  $(window).on("scroll", hasScrolled);

  function hasScrolled() {
    let top = $(window).scrollTop();
    if (top > 500 && top <= lastScrolltop) {
      //setTimeout(addFixed,400);
      //header.addClass('fixed-top');
      header.addClass("fixed-top");
    }

    if (top < 500) {
      header.removeClass("fixed-top");
    }

    if ((header.hasClass("fixed-top") && top > lastScrolltop) || top < 400) {
      //setTimeout(removeFixed, 500);
      header.removeClass("fixed-top");
    }
    lastScrolltop = $(window).scrollTop();
  }

  function addFixed() {
    header.addClass("fixed-top");
  }
  function removeFixed() {
    header.removeClass("fixed-top");
  }
});

	//accordion
	if( $('.fifty-fifty-faqs').length ) {
		if( $('.accordion').length ) {
		  var allPanels = $('.accordion dd').hide();
			
		  $('.accordion dt > a').click(function() {

			var accordionItem = $(this).parents('.accordion_item');

			if(accordionItem.hasClass('active')){
				accordionItem.removeClass('active');
			} else {
				accordionItem.addClass('active');
			}

			if($(this).hasClass('active')){
			  $(this).parent().next().slideUp();
			  $(this).removeClass('active');
			} else {
			  allPanels.slideUp();
			  $('.accordion dt > a').removeClass('active');
			  $(this).addClass('active');
			  $(this).parent().next().slideDown();
			  return false;
			}

		  });
		}
	  }


if($('#video-placeholder').length){
  /**
   * Video Load on Scroll
   */
  document.addEventListener("DOMContentLoaded", function () {
    var observer = new IntersectionObserver(
      function (entries) {
        // Loop over the entries
        entries.forEach(function (entry) {
          // If the element is in view
          if (entry.isIntersecting) {
            // Get the placeholder element
            var videoPlaceholder = document.getElementById("video-placeholder");
            // Create the video element
            var video = document.createElement("video");
            video.autoplay = true;
            video.muted = true;
            video.loop = true;

            // Add the source to the video
            var source = document.createElement("source");
            source.src = videoData.url;
            source.type = videoData.type;
            video.appendChild(source);

            // Replace the placeholder with the video
            videoPlaceholder.innerHTML = "";
            videoPlaceholder.appendChild(video);

            // Unobserve the placeholder so it only loads once
            observer.unobserve(videoPlaceholder);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Target the video placeholder
    var videoPlaceholder = document.getElementById("video-placeholder");
    observer.observe(videoPlaceholder);
  });
}
if( $('.logo-slider').length ) {
  $('.logo-slider').slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
}


jQuery(document).ready(function($) {
  // When the user clicks the button, open the respective modal
  $('.open-modal').on('click', function() {
      var targetModal = $(this).data('target');
      $(targetModal).show();
  });

  // When the user clicks on <span> (x), close the modal
  $('.popup-modal .close').on('click', function() {
      $(this).closest('.popup-modal').hide();
  });

  // When the user clicks anywhere outside of the modal, close it
  $(window).on('click', function(event) {
      if ($(event.target).hasClass('popup-modal')) {
          $(event.target).hide();
      }
  });
});


jQuery(document).ready(function($) {
  // When the user clicks the button, open the respective modal
  $('.open-modal').on('click', function() {
      var targetModal = $(this).data('target'); // Target the correct modal using data-target
      $(targetModal).show(); // Show the targeted modal
  });

  // When the user clicks on <span> (x), close the modal
  $('.popup-modal .close').on('click', function() {
      $(this).closest('.popup-modal').hide(); // Hide the closest modal
  });

  // When the user clicks anywhere outside of the modal, close it
  $(window).on('click', function(event) {
      if ($(event.target).hasClass('popup-modal')) {
          $(event.target).hide(); // Hide the modal if clicked outside of content
      }
  });
});



if ($('.gallery-slider').length) {
  $('.gallery-slider').slick({
    infinite: true,
    slidesToShow: 2.5,
    slidesToScroll: 2.5,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, 
    draggable: true,
    swipeToSlide: true,
    touchThreshold:100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });
}

if( $('.upgrades-slider').length ) {
  $('.upgrades-slider').slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
}

// Assuming the arrows are inside the same container as the slider
var $uslider = $('.upgrades-slider');
  
// Assuming the arrow spans have the classes .arrow-left and .arrow-right
$('.arrow-left').on('click', function () {
    $uslider.slick('slickPrev');
});

$('.arrow-right').on('click', function () {
    $uslider.slick('slickNext');
});

if( $('.review-slider').length ) {
  $('.review-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    draggable: true,
    swipeToSlide: true,
    dots: false,
    autoplay: true,
    arrows: false,
    touchThreshold:100,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1290,
        settings: {
        slidesToShow: 1,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
}
// Assuming the arrows are inside the same container as the slider
  var $slider = $('.review-slider');
  
  // Assuming the arrow spans have the classes .arrow-left and .arrow-right
  $('.arrow-left').on('click', function () {
      $slider.slick('slickPrev');
  });

  $('.arrow-right').on('click', function () {
      $slider.slick('slickNext');
  });

function homeSlider() {
  $(".logo-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // For screens less than or equal to 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For screens less than or equal to 992px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function searchesSlider() {
  $(".home-hero .searches").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    center: false,
    speed: 300,
    slidesToShow: 1.5,
    slidesToScroll: 1,
  });
}


if($('.workshop-facilitators').length){
  jQuery(document).ready(function($) {
    $('.workshop-facilitators').on('click', '.facilitators a', function(e) {
        e.preventDefault();
          var facilitatorID = $(this).data('id');
          var workshopID = $(this).closest('.facilitators').data('workshop-id');

          $.ajax({
              url: "/wp-admin/admin-ajax.php",
              type: 'POST',
              data: {
                  action: 'get_facilitator_data',
                  facilitator_id: facilitatorID,
                  workshop_id: workshopID
              },
              success: function(response) {
                  var $img = $('.background img');
                  $img.attr('src', response.data.main_image_url); 
                  $img.attr('srcset', response.data.main_image_srcset);
                  
                  $('.biography h3').text(response.data.name);
                  $('.biography big').text(response.data.qualifications_cs);
                  $('.biography p').text(response.data.bio);

                  $('.facilitators').html(response.data.facilitators_html);

                  // Handle the response here
                  console.log(response);
              },
              error: function(errorThrown) {
                  console.log(errorThrown);
              }
          });
      });
  });
}


function blogSlider() {
  $(".posts-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function facilitatorSlider() {
  $(".facilitator-slider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 6.8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2.2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
}

/**
 * Facilitator Archive Region Filter
 */
jQuery(document).ready(function($) {
  $('.facil_filters a').on('click', function(e) {
      e.preventDefault();

      var regionId = $(this).data('region');
      $('.facil_filters a').removeClass('active');
      $(this).addClass('active');

      $.ajax({
        url: "/wp-admin/admin-ajax.php",
          type: 'POST',
          data: {
              'action': 'get_facilitators_by_region', // WP AJAX action hook
              'region_id': regionId // The selected region ID
          },
          success: function(response) {
              $('.facilitators-by-region').html(response); // Update the facilitators list
          }
      });
  });
});


//accordion
if( $('.faqs').length ) {
  if( $('.accordion').length ) {
    var allPanels = $('.accordion dd').hide();
    
    $('.accordion dt > a').click(function() {

    var accordionItem = $(this).parents('.accordion_item');

    if(accordionItem.hasClass('active')){
      accordionItem.removeClass('active');
    } else {
      accordionItem.addClass('active');
    }

    if($(this).hasClass('active')){
      $(this).parent().next().slideUp();
      $(this).removeClass('active');
    } else {
      allPanels.slideUp();
      $('.accordion dt > a').removeClass('active');
      $(this).addClass('active');
      $(this).parent().next().slideDown();
      return false;
    }

    });
  }
  }




jQuery(document).ready(function($) {
  /*
  * Magnetic Buttons
  */

  var magnets = document.querySelectorAll('.magnetic')
  var strength = 50

  magnets.forEach( (magnet) => {
  magnet.addEventListener('mousemove', moveMagnet );
  magnet.addEventListener('mouseout', function(event) {
    TweenMax.to( event.currentTarget, 1, {x: 0, y: 0, ease: Power4.easeOut})
  } );
  });

  function moveMagnet(event) {
  var magnetButton = event.currentTarget
  var bounding = magnetButton.getBoundingClientRect()


  TweenMax.to( magnetButton, 1, {
    x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * strength,
    y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * strength,
    ease: Power4.easeOut
  })
  }
});


function facilitatorColSlider() {
  $(".facilitator_colour_slider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 3.7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3.5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.9, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1050, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 380, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.7, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
  $(".facilitator_colour_slider_two").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
}

/**
 * Packages slider
 */
jQuery(document).ready(function ($) {
  $('.slider-packages__slider').slick({
    centerMode: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    centerPadding: '10px',
    dots: false,
    arrows: false,
    variableWidth: true,
    // height

    responsive: [{
      breakpoint: 1400,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,

      }
    }]
  })

  
});

/**
 * If the element with the class "who-we-serve" exists on the page, it adds a click event listener to each item with the class "who-we-serve__accordion-item".
 * When an item is clicked, it checks if it already has the class "open". If it does, it does nothing. If it doesn't, it plays the video inside the clicked item, removes the "no-scroll-play" class from the video, pauses the video in the previously opened item (if any), adds the "no-scroll-play" class to the video in the previously opened item (if any), removes the "open" class from the previously opened item (if any), and adds the "open" class to the clicked item.
 * This code provides a basic functionality for a video accordion component.
 */

// var currentIndex = 0;

// const videos = document.querySelectorAll('div.who-we-serve__accordion > div.who-we-serve__accordion-item > video');
// const progress = document.querySelector('.who-we-serve__accordion-progress');

// const slideTime = 12000;

// var slidePaused = false;

// var progressTimeoutId;

// function openAccordion(element) {
//   $(element).find('video')[0].play();
//   $(element).find('video')[0].classList.remove('no-scroll-play');
//   element.classList.add('open');

//   // Reset the progress bar
//   progress.style.transition = 'none';
//   progress.style.width = '0%';
//   clearTimeout(progressTimeoutId);
//   updateProgress();
// }

// function closeAccordion(element) {
//   $(element).find('video')[0].pause();
//   $(element).find('video')[0].classList.add('no-scroll-play');
//   element.classList.remove('open');
// }

jQuery(document).ready(function ($) {

  // Select the accordion container element
  const whoWeServeAccordion = document.querySelector('.who-we-serve__accordion');
  
  // Select all the accordion items
  const whoWeServeItems = document.querySelectorAll('.who-we-serve__accordion-item');
  
  // Select all the mobile buttons
  const whoWeServeMobileButtons = document.querySelectorAll('.who-we-serve__mobile button');
  
  // Select the progress bar element
  const whoWeServeProgressBar = document.querySelector('.who-we-serve__accordion-progress');

  const pausePlays = document.querySelectorAll('.who-we-serve__accordion-item .pause-play');

  const whoWeServeAccordionProgressPause = document.querySelectorAll('.who-we-serve__accordion-item .pause-play .pause');

  const whoWeServeAccordionProgressPlay = document.querySelectorAll('.who-we-serve__accordion-item .pause-play .play');

  
  // Set the slide time in milliseconds
  const slideTime = 12000;

  // Initialize the current index of the accordion items
  let whoWeServeItemsCurrentIndex = 0;
  
  // Initialize the flag for pausing the progress bar
  let whoWeServeProgressBarPaused = false;

  // Initialize the timeout ID for the progress bar
  var whoWeServeProgressBarTimeoutId;

  var whoWeServeProgressBarSubTimeoutId;


  whoWeServeAccordionProgressPause.forEach((pause) => {
    pause.addEventListener('click', function() {
      pauseWhoWeServeProgressBar();
      pausePlays.forEach(pausePlay => {
        $(pausePlay).addClass('paused');
      });
    });
  });


  whoWeServeAccordionProgressPlay.forEach((play) => {
    play.addEventListener('click', function() {
      playWhoWeServeProgressBar();
      pausePlays.forEach(pausePlay => {
        $(pausePlay).removeClass('paused');
      });
    });
  });





  function pauseWhoWeServeProgressBar() {
    clearWhoWeServeTimeouts();
    resetWhoWeServeProgressBar();
    whoWeServeProgressBarPaused = true;
  }

  function playWhoWeServeProgressBar() {
      // Reset the progress bar
      console.log('reset progress bar');
      resetWhoWeServeProgressBar();
      whoWeServeProgressBarPaused = false;

      // Call the function to update the progress bar after a small delay
      setTimeout(() => {
        whoWeServeUpdateProgressBar();
      }, 100);
  }



  /**
   * Opens the clicked accordion item in the "who-we-serve" accordion.
   * Adds the "open" class to the clicked item and updates the current index.
   * @param {HTMLElement} element - The clicked accordion item.
   */
  function whoWeServeOpenAccord(element) {
    $(element).addClass('open');
    whoWeServeItemsCurrentIndex = $(element).index();
    whoWeServeMobileButtons[whoWeServeItemsCurrentIndex].classList.add('open');
    whoWeServeMobileButtons[whoWeServeItemsCurrentIndex].setAttribute('aria-expanded', 'true');

  }
  /**
   * Closes the currently opened accordion item in the "who-we-serve" accordion.
   */
  function whoWeServeCloseOpenedAccord() {
    $('.who-we-serve__accordion-item.open').removeClass('open');
    whoWeServeDeselectMobileButtons();

  }

  function whoWeServeDeselectMobileButtons() {
    document.querySelector('.who-we-serve__mobile button.open').setAttribute('aria-expanded', 'false');
    document.querySelector('.who-we-serve__mobile button.open').classList.remove('open');
  }

  function whoWeServeSelectMobileButton(){

  }


  whoWeServeMobileButtons.forEach((button, index) => {
    button.addEventListener('click', function() {
      whoWeServeDeselectMobileButtons();
      whoWeServeMobileButtons[index].classList.add('open');
      whoWeServeMobileButtons[index].setAttribute('aria-expanded', 'true');


      console.log('close Accordion');
      whoWeServeCloseOpenedAccord();

      // Open the clicked accordion
      console.log('open Accordion');
      whoWeServeOpenAccord(whoWeServeItems[index]);

      resetWhoWeServeProgressBar();

      if (!whoWeServeProgressBarPaused) {
        // Call the function to update the progress bar after a small delay
        setTimeout(() => {
          whoWeServeUpdateProgressBar();
        }, 100);
      }
    });
  });
      


  // Iterate over each accordion item
  whoWeServeItems.forEach((item, index) => {
    // Add a click event listener to each item
    item.addEventListener('click', () => {
      // Check if the item is already open
      if (item.classList.contains('open')) {
        return; // Do nothing if it's already open
      } else {
        // Close the currently open accordion
        console.log('close Accordion');
        whoWeServeCloseOpenedAccord();

        // Open the clicked accordion
        console.log('open Accordion');
        whoWeServeOpenAccord(item);

        // Reset the progress bar
        console.log('reset progress bar');
        resetWhoWeServeProgressBar();

        if (!whoWeServeProgressBarPaused) {
          // Call the function to update the progress bar after a small delay
          setTimeout(() => {
            whoWeServeUpdateProgressBar();
          }, 100);
        }
     
      }
    });
  });


  /**
   * Clears the timeouts for the progress bar in the "who-we-serve" accordion.
   */
  function clearWhoWeServeTimeouts() {
    if (whoWeServeProgressBarTimeoutId) {
      clearTimeout(whoWeServeProgressBarTimeoutId);
    }

    if (whoWeServeProgressBarSubTimeoutId) {
      clearTimeout(whoWeServeProgressBarSubTimeoutId);
    }
  }

  /**
   * Resets the progress bar for the "who-we-serve" accordion.
   */
  function resetWhoWeServeProgressBar() {
    // Remove transition and set width to 0%
    whoWeServeProgressBar.style.transition = 'none';
    whoWeServeProgressBar.style.width = '0%';

    // Clear the timeout if it exists
    clearWhoWeServeTimeouts();
  }

  /**
   * Updates the progress bar for the "who-we-serve" accordion.
   */
  function whoWeServeUpdateProgressBar() {
    // Clear the previous timeout if it exists
    clearWhoWeServeTimeouts();

    // Set the transition and width of the progress bar
    whoWeServeProgressBar.style.transition = `width ${slideTime}ms linear`;
    whoWeServeProgressBar.style.width = '100%';

    // Set a timeout to reset the progress bar
    whoWeServeProgressBarTimeoutId = setTimeout(() => {
      // Reset the transition and width of the progress bar
      whoWeServeProgressBar.style.transition = 'none';
      whoWeServeProgressBar.style.width = '0%';

      console.log('next slide auto')
      // Close the currently open accordion
      whoWeServeCloseOpenedAccord();

      if (whoWeServeItemsCurrentIndex >= whoWeServeItems.length - 1) {
        whoWeServeItemsCurrentIndex = 0;
      } else {
        whoWeServeItemsCurrentIndex++;
      }

      // Open the next accordion
      whoWeServeOpenAccord(whoWeServeItems[whoWeServeItemsCurrentIndex]);

      // Call the function again after a small delay
      whoWeServeProgressBarSubTimeoutId = setTimeout(() => {
        whoWeServeUpdateProgressBar();

      }, 100);

    }, slideTime);
  }


  whoWeServeUpdateProgressBar();

//   if ($('.who-we-serve').length) {

//     $('.who-we-serve__accordion-item').on('click', function () {
//       if (this.classList.contains('open')) {
//         return;
//       } else {
//         closeAccordion($('.who-we-serve__accordion-item.open')[0]);
//         openAccordion(this);
//       }
//     });

//     $('.who-we-serve__mobile button').on('click', function () {
//       if (this.classList.contains('open')) {
//         return;
//       }

//       closeAccordion($('.who-we-serve__accordion-item.open')[0]);

//       this.classList.add('open');
//       var index = $(this).index();

//       $('.who-we-serve__mobile button').attr('aria-expanded', 'false');
//       this.setAttribute('aria-expanded', 'true');

//       $('.who-we-serve__mobile button.open').removeClass('open');
//       this.classList.add('open');
//       openAccordion($('.who-we-serve__accordion-item').eq(index)[0]);
//     });

//   }


//   function updateProgress() {
//     progress.style.transition = `width ${slideTime}ms linear`;
//     progress.style.width = '100%';
//     progressTimeoutId = setTimeout(() => {
//         // Add a small delay before resetting the transition and width
//         progress.style.transition = 'none';
//         progress.style.width = '0%';
//         setTimeout(() => {
//             closeAccordion($('.who-we-serve__accordion-item')[currentIndex]);
//             currentIndex = currentIndex + 1;
//             if (currentIndex >= videos.length) {
//                 currentIndex = 0;
//             }
//             openAccordion($('.who-we-serve__accordion-item')[currentIndex]);

//             updateProgress();
//         }, 100); // 100ms should be enough, but you can increase this if needed
//     }, slideTime);
// }


//   updateProgress();


//   const pausePlays = document.querySelectorAll('.who-we-serve__accordion-item .pause-play');

//   pausePlays.forEach(pausePlay => {
//     pausePlay.addEventListener('click', function() {
//         slidePaused = !slidePaused;
//         if (slidePaused) {
//             progress.style.transition = 'none';
//             progress.style.width = '0%';
//             clearTimeout(progressTimeoutId);
//         } else {
//             updateProgress();
//         }
//     });
// });
 

});

/**
 * Lazy Loading videos.
 * 
 * This code listens for the DOMContentLoaded event, which indicates that the initial HTML document has been completely loaded and parsed.
 * It then selects all the videos with the class "lazy" and creates an IntersectionObserver to control their playback based on scroll position.
 */
document.addEventListener("DOMContentLoaded", function () {
  const scrollVideos = document.querySelectorAll("video.lazy");

  if ("IntersectionObserver" in window) {
    /**
     * Intersection observer for controlling video playback based on scroll position.
     *
     * @type {IntersectionObserver}
     */
    const scrollVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          // Load the video source when it becomes visible in the viewport
          Array.from(video.target.children).forEach(function (videoSource) {

            // if window width less thatn 992px
            if (window.innerWidth < 992) {
              if (videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.srcmobile;
              }
            } else {
              if (videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
              }
            }

   
          });

          

          // Load the video and remove the "lazy" class
          video.target.load();
          video.target.classList.remove("lazy");

          // Pause the video if it has the "lazy-pause" class
          if (video.target.classList.contains("lazy-pause")) {
            video.target.pause();
          }

          // Stop observing the video once it has been loaded and played
          scrollVideoObserver.unobserve(video.target);
        }
      });
    });

    // Observe each scroll-play video
    scrollVideos.forEach(function (scrollVideo) {
      scrollVideoObserver.observe(scrollVideo);
    });
  }
});


/**
 * This code listens for the DOMContentLoaded event, which indicates that the initial HTML document has been completely loaded and parsed.
 * It then selects all the videos with the class "scroll-play" and creates an IntersectionObserver to control their playback based on scroll position.
 */
document.addEventListener("DOMContentLoaded", function () {
  const scrollVideos = document.querySelectorAll("video.scroll-play");

  if ("IntersectionObserver" in window) {
    /**
     * Intersection observer for controlling video playback based on scroll position.
     *
     * @type {IntersectionObserver}
     */
    const scrollVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        
      
        // Skip videos with 'lazy' or 'no-scroll-play' class
        if (video.target.classList.contains('lazy') || video.target.classList.contains('no-scroll-play')) {
          return;
        }

        // If the video is currently intersecting with the viewport, play it.
        if (video.isIntersecting) {
          video.target.play();
        } else {
          // If the video is not intersecting with the viewport, pause it.
          video.target.pause();
        }
      });
    });

    // Observe each scroll video element.
    scrollVideos.forEach(function (scrollVideo) {
      scrollVideoObserver.observe(scrollVideo);
    });
  }
});

function isMainScrollBarVisible() {
  return document.body.scrollHeight > window.innerHeight;
}

 
  /**
   * Adjusts the width and float of an element based on the offset from the right or left side of its container.
   * @param {string} containerSelector - The selector for the container element.
   * @param {string} elementSelector - The selector for the element to adjust the width and float of.
   * @param {string} direction - The direction to adjust the width and float. Can be 'right' or 'left'.
   */
  function containerElementFullWidth(containerSelector, elementSelector, direction, stopAtWidth = 992) {
    const containerElement = document.querySelector(containerSelector);
    const element = document.querySelector(elementSelector);

    console.log(containerElement);
    console.log(element);

    var scrollBarOffset = Math.floor((window.innerWidth - document.documentElement.clientWidth) / 2);
   
    let offset;

    // Calculate the offset based on the direction
    if (direction === 'right') {
      offset = window.innerWidth - containerElement.getBoundingClientRect().right - scrollBarOffset;
    } else if (direction === 'left') {
      offset = containerElement.getBoundingClientRect().left;
    } else {
      throw new Error('Invalid direction. Must be "right" or "left".');
    }

    // Set the width of the element to include the offset
    element.style.width = `calc(100% + ${offset}px)`;

    // Set the float of the element based on the direction
    if (direction === 'right') {
      element.style.float = 'left';
    } else if (direction === 'left') {
      element.style.float = 'right';
    }

    // Add event listener for window resize to update the width when the window size changes
    window.addEventListener('resize', function() {
      let offset;

      if (window.innerWidth < stopAtWidth) {
        console.log('we good')
        element.style.width = 'auto';
        return;
      }

      scrollBarOffset = Math.floor((window.innerWidth - document.documentElement.clientWidth) / 2);


      // Recalculate the offset based on the direction
      if (direction === 'right') {
        offset = window.innerWidth - containerElement.getBoundingClientRect().right - scrollBarOffset;
      } else if (direction === 'left') {
        offset = containerElement.getBoundingClientRect().left;
      } else {
        throw new Error('Invalid direction. Must be "right" or "left".');
      }

      // Update the width of the element to include the new offset
      element.style.width = `calc(100% + ${offset}px)`;
    });
  }

  // Call the containerElementFullWidth function if the element with id "map" exists
  if ($('#map').length) {
    containerElementFullWidth('.gmap .col-12', '#map', 'right');
  }

  if ($('.fifty-fifty-with-image').length) {
    containerElementFullWidth('section.fifty-fifty-with-image .images-column', '.fifty-fifty-image-wrapper', 'right');
  }

  if ($('.inner-hero').length) {
    containerElementFullWidth('.inner-hero .col-12', '.inner-hero__content', 'right');
  }

  if ($('.two-image-block').length) {
    console.log(123)
    containerElementFullWidth('.two-image-block .col-12', '.two-image-block .content', 'right');
  }



  jQuery(document).ready(function($) {
    // Control Modal
    var modal = $('#main-menu');
    var modalToggle = $('.mobile-menu-activate');
    var modalClose = $('.modal-close');

    modalToggle.on('click', function(e) {
        e.preventDefault();
        
        if (modal.attr('aria-hidden') === 'true') {
            modal.attr('aria-hidden', 'false').removeAttr('hidden');
        } else {
            modal.attr('aria-hidden', 'true').attr('hidden', 'hidden');
        }

      $('body').toggleClass('overflow-hidden');

        modal.toggleClass('open');
        modalToggle.toggleClass('open');
    });

    modalClose.on('click', function(e) {
        e.preventDefault();
        modal.attr('aria-hidden', 'true').attr('hidden', 'hidden');
        modal.removeClass('open');
        modalToggle.removeClass('open');
        $('body').removeClass('overflow-hidden');

    });

    // Accessibility
    var focusableElements = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';
    var firstFocusableElement = modal.find(focusableElements).first();
    var lastFocusableElement = modal.find(focusableElements).last();

    jQuery(document).on('keydown', function(e) {
    
        if ((e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && modal.attr('aria-hidden') === 'false'){
            modal.attr('aria-hidden', 'true').attr('hidden', 'hidden');
            modal.removeClass('open');
            modalToggle.removeClass('open');
            $('body').removeClass('overflow-hidden');

        }

      
    });
});


// Mobile menu accordion

const mobileMenuAccordions = document.querySelectorAll('#main-menu .menu-item-has-children a');

mobileMenuAccordions.forEach((accordion) => {
  accordion.addEventListener('click', (event) => {

    if (window.innerWidth >= 992) return;
    if (accordion.parentElement.classList.contains('active')) return;
    
    event.preventDefault();
    const submenu = accordion.nextElementSibling;
    accordion.parentElement.classList.toggle('active');

    if (submenu.style.maxHeight) {
      submenu.style.maxHeight = null;
    } else {
      submenu.style.maxHeight = submenu.scrollHeight + 'px';
    }
  });
});

// .whatsapp-contact.floater on click
jQuery(document).ready(function($) {
  const $whatsappContactFloater = $('.whatsapp-contact.floater');
  const $whatsappContactActivate = $('.whatsapp-contact__activate');

  let resizeTimer;
  $(window).on('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if (window.innerWidth < 992) {
        $whatsappContactFloater.removeClass('open');
      }
    }, 200);
  });

  $whatsappContactActivate.on('click', function(e) {
    if (window.innerWidth >= 992) {
      e.preventDefault();
      $('.whatsapp-contact.floater').toggleClass('open');
    }
  });
});